import firebase from 'firebase/compat/app';

import { SubscriptionType } from '../enums/subscription-type';
import { Mapper } from '../interfaces/mapper';
import { RosterV2 } from '../interfaces/team';
import { TeamModel } from '../models/team';

export class TeamMapper<T> implements Mapper<TeamModel<string | T>> {
  fromSnapshot(snapshot: firebase.firestore.DocumentSnapshot): TeamModel<string> {
    const data = snapshot.data()!;

    if (!data) throw new Error('No data on team mapper');

    return new TeamModel<string>(
      snapshot.ref as any,
      data[TeamModel.NAME],
      data[TeamModel.DESCRIPTION],
      data[TeamModel.PHOTO_URL],
      data[TeamModel.SPORT],
      data[TeamModel.DISCOVERABLE],
      data[TeamModel.SUBSCRIPTION],
      data[TeamModel.AGE_RANGE],
      data[TeamModel.LOCATION],
      data[TeamModel.CREATED],
      data[TeamModel.UPDATED],
      data[TeamModel.WEBSITE],
      data[TeamModel.DISPLAY_PUBLIC_WEBSITE],
      data[TeamModel.ONLINE],
      data[TeamModel.SKILL_LEVEL],
      data[TeamModel.ROSTER] as RosterV2<string>[],
    );
  }

  toMap(item: TeamModel<T>): firebase.firestore.DocumentData {
    return {
      id: item.id,
      [TeamModel.NAME]: item.name,
      [TeamModel.DESCRIPTION]: item.description,
      [TeamModel.PHOTO_URL]: item.photoURL,
      [TeamModel.SPORT]: item.sport,
      [TeamModel.DISCOVERABLE]: item.discoverable ?? false,
      [TeamModel.SUBSCRIPTION]: item.subscription ?? SubscriptionType.ROOKIE,
      [TeamModel.AGE_RANGE]: item.ageRange ?? null,
      [TeamModel.LOCATION]: item.location ?? null,
      [TeamModel.CREATED]: item.created ?? null,
      [TeamModel.UPDATED]: item.updated ?? null,
      [TeamModel.WEBSITE]: item.website ?? null,
      [TeamModel.DISPLAY_PUBLIC_WEBSITE]: item.displayPublicWebsite ?? false,
      [TeamModel.ONLINE]: item.online ?? false,
      [TeamModel.SKILL_LEVEL]: item.skillLevel ?? null,
      [TeamModel.ROSTER]: item.roster,
    };
  }
}
