import 'firebase/firestore';

import firebase from 'firebase/compat/app';

import { AgeRange } from '../enums/age-range';
import { SkillLevel } from '../enums/skill-level';
import { SubscriptionType } from '../enums/subscription-type';
import { Location } from '../interfaces/location';
import { RosterV2, Team, TeamWebsite } from './../interfaces/team';
import { DBModel } from './model.db';

export class TeamModel<T> extends DBModel {
  static readonly NAME = 'name';
  static readonly DESCRIPTION = 'description';
  static readonly PHOTO_URL = 'photoURL';
  static readonly SPORT = 'sport';
  static readonly DISCOVERABLE = 'discoverable';
  static readonly SUBSCRIPTION = 'subscription';
  static readonly AGE_RANGE = 'ageRange';
  static readonly LOCATION = 'location';
  static readonly CREATED = 'created';
  static readonly UPDATED = 'updated';
  static readonly WEBSITE = 'website';
  static readonly DISPLAY_PUBLIC_WEBSITE = 'displayPublicWebsite';
  static readonly ROSTER = 'roster';
  static readonly ONLINE = 'online';
  static readonly SKILL_LEVEL = 'skillLevel';

  constructor(
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined,
    public name: string,
    public description: string | undefined,
    public photoURL: string | undefined,
    public sport: string,
    public discoverable: boolean,
    public subscription: SubscriptionType,
    public ageRange: AgeRange | string,
    public location: Location,
    public created: firebase.firestore.Timestamp | undefined,
    public updated: firebase.firestore.Timestamp | undefined,
    public website: TeamWebsite,
    public displayPublicWebsite: boolean,
    public online: boolean = false,
    public skillLevel: SkillLevel | Array<SkillLevel> | undefined,
    public roster: RosterV2<T>[] = [],
  ) {
    super(ref);
  }

  static fromJSON(
    team: Team,
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  ) {
    return new TeamModel(
      ref,
      team.name,
      team.description,
      team.photoURL,
      team.sport || '',
      team.discoverable,
      team.subscription,
      team.ageRange,
      team.location,
      team.created,
      team.updated,
      team.website,
      team.displayPublicWebsite,
      team.online,
      team.skillLevel,
    );
  }
}
